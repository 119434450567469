import React from 'react'
import './Appointment.css'
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppointmentAPI } from './AppointmentAPI';
import { LoadingButton } from "@mui/lab";
import { ValidContact, ValidEmail } from '../../Components/helpers/Validators';
import { getNativeSelectUtilityClasses } from '@mui/material';

import { FooterAPI } from '../../Components/Footer/FooterAPI';
import useNav from '../../Components/Navbar/useNav';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';



const Appointment = ({ setSuccessOpen, setErrorOpen, setMessage }) => {

  const { study, setStudy } = useNav();


  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [topic, setTopic] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });


  useEffect(() => {
    setLoading(true);
    FooterAPI.get()
      .then((res) => {
        console.log(res.data.data.setting)
        let response = res.data.data.setting;
        setData(response)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, 'error message')
        setLoading(false);
      })
  }, [])


  const [validationMessage, setValidationMessage] = useState({
    name: null,
    phone: null,
    age: null,
    email: null,
    address: null,
    topic: null,
    country: null,
  });


  const postSubmit = async (e) => {

    e.preventDefault();
    if (name === '') {
      setValidationMessage({
        name: "Name cannot be empty",
        phone: null,
        age: null,
        email: null,
        address: null,
        topic: null,
        country: null,
      });
      return;
    } else if (phone === '') {
      setValidationMessage({
        name: null,
        phone: "Phone number cannot be empty",
        age: null,
        email: null,
        address: null,
        topic: null,
        country: null,
      });
      return;
    } else if (ValidContact(phone) === false) {
      setValidationMessage({
        name: null,
        phone: "Invalid contact number",
        age: null,
        email: null,
        address: null,
        topic: null,
        country: null,
      });
      return;
    } else if (age === "") {
      setValidationMessage({
        name: null,
        phone: null,
        age: 'Age cannot be empty',
        email: null,
        address: null,
        topic: null,
        country: null,
      });
      return;
    } else if (email === "") {
      setValidationMessage({
        name: null,
        phone: null,
        age: null,
        email: "Email cannot be empty",
        address: null,
        topic: null,
        country: null,
      });
      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        name: null,
        phone: null,
        age: null,
        email: "Invalid email address",
        address: null,
        topic: null,
        country: null,
      });
      return;
    } else if (address === "") {
      setValidationMessage({
        name: null,
        phone: null,
        age: null,
        email: null,
        address: 'Address cannot be empty',
        topic: null,
        country: null,
      });
      return;
    } else if (country === "") {
      setValidationMessage({
        name: null,
        phone: null,
        age: null,
        email: null,
        address: null,
        topic: null,
        country: 'Please select a country',
      });
      return;
    } else if (topic === "") {
      setValidationMessage({
        name: null,
        phone: null,
        age: null,
        email: null,
        address: null,
        topic: 'Topic cannot be null',
        country: null,
      });
      return;
    } else {
      setValidationMessage({
        name: null,
        phone: null,
        age: null,
        email: null,
        address: null,
        topic: null,
        country: null,
      });
      setLoading(true)
      await AppointmentAPI.get({
        name: name,
        phone_no: phone,
        age: age,
        email: email,
        address: address,
        country: country,
        topic: topic
      }).then(res => {
        if (res.data.status === true) {
          setName('')
          setPhone('')
          setAge('')
          setEmail('')
          setAddress('')
          setCountry('')
          setTopic('')
          enqueueSnackbar("Your appointment was sent successfully our team members will get in touch with you earliest possible.",{variant:"success"});
        }
        else {
          setMessage(res.data.message)
          setSuccessOpen(false)
          setErrorOpen(true)
        }
      }).catch(error => {
        setErrorOpen(true)
        setSuccessOpen(false)
        if (error.response) {
          setMessage(error.response.data.message)
        }
        // else {
        //   setMessage("Server Error")
        // }
      })
      setLoading(false)
    }
    setTimeout(() => {
    }, 5000)
  }



  return (
    <>
      <div className="container contact-form appointment-section-field">
        <div classname='section-title'>
          <h2 className='text-center can-mar inquiry-now'>Appointment </h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">Name
            </label>
            <br />
            <input type="text" name="Name" id="" placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
              style={
                validationMessage.name
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            />


            {validationMessage.name ? (
              <span className="error error-message">
                {validationMessage.name}
              </span>
            ) : null}
          </div>

          <div className="col-md-4">
            <label htmlFor="">Phone No
            </label>
            <br />
            <input type="number" name="Phone" id="" placeholder='Number' min="10" max="100"
              value={phone}
              maxLength={10}
              onChange={(e) => setPhone(e.target.value)}
              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
              style={
                validationMessage.phone
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            />


            {validationMessage.phone ? (
              <span className="error error-message">
                {validationMessage.phone}
              </span>
            ) : null}
          </div>


          <div className="col-md-2">
            <label htmlFor="">Age
            </label>
            <br />
            <input type="number" name="age" id="" placeholder='Age'
              value={age}
              onChange={(e) => setAge(e.target.value)}
              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
              style={
                validationMessage.age
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            />

            {validationMessage.age ? (
              <span className="error error-message">
                {validationMessage.age}
              </span>
            ) : null}
          </div>

        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">Email
            </label>
            <br />
            <input type="email" placeholder='Email' name='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={
                validationMessage.email
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            />


            {validationMessage.email ? (
              <span className="error error-message">
                {validationMessage.email}
              </span>
            ) : null}

          </div>
          <div className="col-md-6">
            <label htmlFor="">Address
            </label>
            <br />
            <input type="text" placeholder='Address' name='address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
              style={
                validationMessage.address
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            />


            {validationMessage.address ? (
              <span className="error error-message">
                {validationMessage.address}
              </span>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">Country to apply
            </label>
            <br />
            <select name="" id="" value={country} onChange={(e) => setCountry(e.target.value)}
              style={
                validationMessage.country
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            >
              <option selected value="">Countries</option>
              {
                study.map((elem) => {
                  return (
                    <>

                      <option>{elem.destination_title}</option>
                    </>
                  )
                })
              }

              {/* <option selected value="">Countries</option>
              <option>Australia</option>
              <option>Uk</option>
              <option>Canada</option>
              <option>Norway</option> */}

            </select>
            {validationMessage.country ? (
              <span className="error error-message">
                {validationMessage.country}
              </span>
            ) : null}


          </div>
          <div className="col-md-6">
            <label htmlFor="">Topic
            </label>
            <br />
            <input type="text" name="Name" id="" placeholder='Topic'
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
              style={
                validationMessage.topic
                  ? {
                    border: "1px solid red",
                  }
                  : null
              }
            />


            {validationMessage.topic ? (
              <span className="error error-message">
                {validationMessage.topic}
              </span>
            ) : null}


          </div>
        </div>

        <LoadingButton className="btn btn-main ml-2" type='submit' loading={loading} onClick={postSubmit}>Apply</LoadingButton>

      </div>
    </>
  )
}

export default Appointment