import axios from "axios";
import React, { useEffect, useState } from "react";

const useNav = () => {
  const [study, setStudy] = useState([]);
  const [service, setService] = useState([]);
  const [servicedetail, setServicedetail] = useState(null);
  const [loading,setLoading]=useState(false);
  const [id, setId] = useState(null);
  // console.log("Id from useNav", id);
  useEffect(() => {
 
    axios({
      url: `${process.env.REACT_APP_API_URL}/studyabroad/get`,
      method: "GET",
    })
      .then((res) => {
        let response = res.data.data.studyabroads;
        setStudy(response);
      })
      .catch((err) => {
        console.log(err, "your Error is");
      });
  }, []);
  // services
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/getAllServices`,
      method: "GET",
    })
      .then((res) => {
        let response = res.data.data.services;
        setService(response);
      })
      .catch((err) => {
        console.log(err, "your Error is");
      });
  }, []);
  //single services
  useEffect(() => {
    setLoading(true);
    if (id) {
   
      axios({
        url: `${process.env.REACT_APP_API_URL}/getSingleService/${id}`,
        method: "GET",
      })
        .then((res) => {
          let response = res.data.data.service;
          setServicedetail(response);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "your Error is");
          setLoading(false);
        });
    }
  }, [id]);

  const [train, setTrain] = useState([]);
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/training/get`,
      method: "GET",
    })
      .then((res) => {
        let response = res.data.data.trainings;
        setTrain(response);
      })
      .catch((err) => {
        console.log(err, "Your Error is");
      });
  }, []);

  return {
    study,
    setStudy,
    service,
    setService,
    servicedetail,
    setServicedetail,
    train,
    setTrain,
    id,
    setId,
    loading,
    setLoading
  };
};

export default useNav;
