import React from "react";
import "./Blogpage.css";
import parse from 'html-react-parser';
import { useState } from "react";
import Canada from "../../Components/assets/images/canada.jpg";
import Image09 from "../../Components/assets/images/image08.jpg";
import Image1 from "../../Components/assets/images/blog-img1.jpg";
import Image2 from "../../Components/assets/images/blog-img2.jpg";
import Image3 from "../../Components/assets/images/blog-img3.jpg";

import { Link } from "react-router-dom";
import useBlog from "./useBlog";
import Blogpageloader from "./Loader/Blogpageloader";

const Blogpage = () => {
  const { blog, setBlog, loading, setLoading } = useBlog();
  // console.log("blog",blog)
  // {
  //   image: Image09,
  //   title: "How to get good TOEFL score",
  //   subtitle:
  //     "How to get good TOEFL score? “Is it difficult to get a good score in TOEFL?”is one of the most common questionsasked by the students. My response to it would be an absolute “NO”. TOEFL is an easy test of English as a foreign language normally to evaluate your English language ability. Simply taking care [...]",
  //   but: 'Read more'
  // },
  // {
  //   image: Image09,
  //   title: "7 Problems you will face and solve as an international student",
  //   subtitle:
  //     "Homesickness It’s normal- if you’re homesick and missing your family, or even missing your neighborhood choila,  you are not unique- this happens to every international student. So expect it and deal with it. Make more friends, create your own social and academic circles, get more involved in campus or community activities, take a night out [...]",
  //   but: 'Read more'
  // },
  // {
  //   image: Image09,
  //   title: "IELTS Writing Task 1- Pie Chart",
  //   subtitle:
  //     "IELTS Writing Task 1- Pie Chart A common diagram in the IELTS Writing Task 1 is the Pie Chart where you will be required to write a short descriptive report based on the visual data in the diagram. Pie chart presents data in a circle where the divisions in the pie chart represents a proportion [...]",
  //   but: 'Read more'
  // },
  // {
  //   image: Image09,
  //   title: "Improving your Fluency- IELTS Speaking",
  //   subtitle:
  //     "Improving your Fluency- IELTS Speaking ‘Fluency and Coherence’ is one of the four band descriptors in the IELTS Speaking. But what does it mean to be fluent? Being able to speak fluent English means the ability to speak smoothly, easily and, readily. It means the use of normal level of continuity, rate and effort which [...]",
  //   but: 'Read more'
  // },
  // {
  //   image: Image09,
  //   title: "LEXIS and VOCABULARY- IELTS Speaking",
  //   subtitle:
  //     "LEXIS and VOCABULARY- IELTS Speaking Lexis and Vocabulary is another one of the four band descriptors of IELTS speaking and it doesn’t only mean learning new word items. Lexis and Vocabulary covers various other aspects that includes use of collocations, idiomatic phrases, flexibility, precision of words choice, paraphrasing, style/ register and using less common words. [...]",
  //   but: 'Read more'
  // },

  return (
    <>
      <section>
        <div className="">
          <div className="section-image card-overlay">
            <img src={Canada} alt="" className="img-fluid" />
          </div>
          <div className="section-study">
            <h1>Blogs</h1>
          </div>
        </div>

        <div className="container">
          <div className="blog-header">
            <h2>Blog Post</h2>
      
          </div>

          <div className="row mt-2">
            {
              loading ? <><Blogpageloader /></> : <>

                {
                  blog.map((elem) => {
                    return (
                      <>
                        <div className="col-lg-4">
                          <div className="card" style={{ width: "20rem" }}>
                            <div className="image-item">
                              <img src={elem.image} className="card-img-top" alt="image" />
                            </div>
                            <div className="card-body">
                              <span className="card-title">Category</span>
                              <h6 className="subtitle mt-3">
                                {elem?.title}
                              </h6>
                              {
                                parse(elem?.description.substr(0, 500) + "..." || '')
                              }
                              <Link to={`/blogdetails/${elem.slug}`}>
                                <button className="btn-read">Read full story..</button>
                              </Link>
                             
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogpage;
