import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useAbout = () => {
  const [abt, setAbt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}/get-about-us`,
      method: 'GET'
    }).then((res) => {
      console.log(res.data.data.about)
      let response = res.data.data.about;
      setAbt(response);
      setLoading(false);
    }).catch((err) => {
      console.log(err, "Your error is")
      setLoading(false);
    })
    axios({
      url: `${process.env.REACT_APP_API_URL}/get-infographic-us`,
      method: 'GET'
    }).then((res) => {
      let response = res.data.data.infographic;
      setCounter(response);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    })

  }, [])
  return {
    loading,
    abt,
    setAbt,
    counter, 
    setCounter
  }
}

export default useAbout