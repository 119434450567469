import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const BlogpagedetailsLoader = () => {
  return (
    <>
      <div className='row'>
                <div className='col-md-12'>
                    <Stack spacing={1}>
                    
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                        <Skeleton variant="text" width={'100%'} />
                    </Stack>
                </div>
         
            
            </div>
        </>
    
  )
}

export default BlogpagedetailsLoader