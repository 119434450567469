import React from 'react'
import './Testimonialspage.css'
// import { useState } from 'react'
import Canada from '../../Components/assets/images/canada.jpg'
// import Image7 from '../../Components/assets/images/image07.jpg'
// import Image8 from '../../Components/assets/images/image08.jpg'
import useTestimonial from './useTestimonial'
import Testimonialloader from './Testimonialloader'

const TestimonialsDetails = () => {
const {monials,setMonials,loading,setLoading} = useTestimonial();

// const testimonial = monials.testimonials




        // {
        //     image: Image8,
        //     name: "Meghna Gautam",
        //     college: "College of  ldaho",
        //     scholor: "Scholorship $7500",
        //     desc: "It was, has and will always be a pleasure being a part of NAVC. Definitely, it was a roller coaster ride. The learning environment here is so different from others. Thank you to each and everyone who’s been part of my journey. Will always be grateful to you. You owe my love."
        // },
        // {
        //     image: Image8,
        //     name: "Poonam pandey",
        //     college: "College of  ldaho",
        //     scholor: "Scholorship $5500",
        //     desc: "It was, has and will always be a pleasure being a part of NAVC. Definitely, it was a roller coaster ride. The learning environment here is so different from others. Thank you to each and everyone who’s been part of my journey. Will always be grateful to you. You owe my love."
        // },
        // {
        //     image: Image7,
        //     name: "Rabina Silwal",
        //     college: "College of  ldaho",
        //     scholor: "Scholorship $6500",
        //     desc: "It was, has and will always be a pleasure being a part of NAVC. Definitely, it was a roller coaster ride. The learning environment here is so different from others. Thank you to each and everyone who’s been part of my journey. Will always be grateful to you. You owe my love."
        // },
        // {
        //     image: Image7,
        //     name: "Bharat Thapa Magar",
        //     college: "College of  ldaho",
        //     scholor: "Scholorship $6500",
        //     desc: "It was, has and will always be a pleasure being a part of NAVC. Definitely, it was a roller coaster ride. The learning environment here is so different from others. Thank you to each and everyone who’s been part of my journey. Will always be grateful to you. You owe my love."
        // },
        // {
        //     image: Image8,
        //     name: "Aarjan Bhatta",
        //     college: "College of  ldaho",
        //     scholor: "Scholorship $8500",
        //     desc: "It was, has and will always be a pleasure being a part of NAVC. Definitely, it was a roller coaster ride. The learning environment here is so different from others. Thank you to each and everyone who’s been part of my journey. Will always be grateful to you. You owe my love."
        // }
   

    return (
        <>
            <div>
                <section>
                    <div className="">
                        <div className='section-image card-overlay'>
                            <img src={Canada} alt="" className='img-fluid' />
                        </div>
                        <div className='section-study'>
                            <h1>Testimonials</h1>
                        </div>
                    </div>
                    <div className="container">
                       
                         <div className="row ">
                            {
                                loading?<>
                                <Testimonialloader />
                                </>:
                                <>
                              {
                                monials?.map((elem) => {
                                    return (
                                        <>
                                            <div className="col-md-4 can-mar card-image">
                                                <div className='card-image blog-card-image'>
                                                    <img src={elem.image} alt="" className='' />
                                                </div>

                                            </div>

                                            <div className="col-md-8 can-mar card-details">
                                                <p className='card-college-para'>
                                                    <span className="quote-detail">
                                                        {" "}
                                                        <sup><i className="fa-solid fa-quote-left" /></sup>
                                                    </span>
                                                    {elem.description}
                                                    <span className="quote-detail">
                                                       <sup> <i className="fa-solid fa-quote-right" /></sup>
                                                    </span></p>
                                                <div>
                                                    <h3>{elem.name}</h3>
                                                    <p className='card-college'>{elem.institution_name}</p>
                                                    <p className='intro card-scholorship'>{elem.scholor}</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }  
                                </>
                            }
                            
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default TestimonialsDetails