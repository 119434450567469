import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


export default function Trainingclassloader() {
    return (
        <>
              <div className='row'>
                <div className='col-md-3'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={250} height={200} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                    </Stack>
                </div>
                <div className='col-md-3'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={250} height={200} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                    </Stack>
                </div>
                <div className='col-md-3'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={250} height={200} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                    </Stack>
                </div>
                <div className='col-md-3'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={250} height={200} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={250} />
                    </Stack>
                </div>
            </div>
        </>

    );


}
