import React from 'react'
import './Aboutpage.css'
import parse from 'html-react-parser'
import Image1 from '../../Components/assets/images/canada.jpg'
// import Image2 from '../../Components/assets/images/student-img.webp'
import Image3 from '../../Components/assets/images/student-img2.webp'
// import Banner from '../../Components/BodyComponent/Banner'
import useAbout from './useAbout'
import Aboutloader from './Aboutloader'


export default function Aboutpage() {
  const {abt,setAbt,loading}=useAbout();
 

  return (
    <>
      {/* <Banner subtitle={"About Us "} title={"About Us"} /> */}

      <section>

        <div>
          <div className='section-image card-overlay'>
            <img src={Image1} alt="" className='img-fluid' />
          </div>
          <div className='section-study'>
            <h1>About Us</h1>
          </div>
        </div>

        <div className="innerpage">
          <div className="who_we_are">
            <div className="container">
              <div className="row">
                {/* <div className="content_header">
                  <h2 className='mb-3'>WHO WE ARE</h2>
                  <p>Once the students have sat for their final exam at various local testing centers in Kathmandu, NAVC counsels prospective students for their higher education in foreign countries. To select the right college and university for a Nepali student is very difficult, but well-trained counselors provide all the necessary information about colleges and universities and the lengthy processing system for the student visa application in various embassies.</p>
                </div> */}
                {/* <div className="second-row">
                  <p>NAVC’s student counselors undergo an intensive training before they are delegated to provide any information on colleges or universities in the USA, UK, Australia, New Zealand, Ireland, Canada.</p>
                  <p>Once the students have sat for their final exam at various local testing centers in Kathmandu, NAVC counsels prospective students for their higher education in foreign countries. To select the right college and university for a Nepali student is very difficult, but well-trained counselors provide all the necessary information about colleges and universities and the lengthy processing system for the student visa application in various embassies.</p>
                </div> */}




                {/* <div className="col-sm-6 content_header col-content">
                  <h2>WHO WE ARE</h2>
                  <p>Once the students have sat for their final exam at various local testing centers in Kathmandu, NAVC counsels prospective students for their higher education in foreign countries. To select the right college and university for a Nepali student is very difficult, but well-trained counselors provide all the necessary information about colleges and universities and the lengthy processing system for the student visa application in various embassies.</p>
                </div> */}

                {/* <div className="col-sm-6 second-row">
                  <p>NAVC’s student counselors undergo an intensive training before they are delegated to provide any information on colleges or universities in the USA, UK, Australia, New Zealand, Ireland, Canada.</p>
                  <p>Once the students have sat for their final exam at various local testing centers in Kathmandu, NAVC counsels prospective students for their higher education in foreign countries. To select the right college and university for a Nepali student is very difficult, but well-trained counselors provide all the necessary information about colleges and universities and the lengthy processing system for the student visa application in various embassies.</p>
                </div> */}

              </div>
            </div>
          </div>
        </div>

        <div className=''>
           <div className='container'>
          
             

    <div className='row'>
      {
        loading?<>
        <Aboutloader />

        </>
        :<>
         <div className='about-img mission'>
               {abt?.image? <img className='about-main-img mission' src={abt?.image} alt="mission-img" />:null}
              </div>
              <div className='about-wrapper mission'>
                  <div className='about-header mission'>
                    {/* <h2>Our Mission</h2> */}
                  </div>
                  <div className='about-content mission mt-4'>
                    <p>
                      
                    {
                      parse(abt.description? abt.description:'')
                    }
                    </p>
                    {/* <p>Once the students have sat for their final exam at various local testing centers in Kathmandu, NAVC counsels prospective students for their higher education in foreign countries. To select the right college and university for a Nepali student is very difficult, but well-trained counselors provide all the necessary information about colleges and universities and the lengthy processing system for the student visa application in various embassies.</p>
                    <p>NAVC’s student counselors undergo an intensive training before they are delegated to provide any information on colleges or universities in the USA, UK, Australia, New Zealand, Ireland, Canada.</p> */}
                  </div>
                </div>
        </>
      }

             
              
            </div>

            
          
          </div> 
        </div>


        {/* <div className='vision-section'>
          <div className='container'>
            <div className='row'>
              <div className='about-img mission'>
                <img className='about-main-img mission' src={Image3} alt="mission-img" />
              </div>
              <div className='about-wrapper mission'>
                <div className='about-header mission'>
                  <h2>Our Vision</h2>
                </div>
                <div className='about-content mission'>
                  <p>Our vision is to bring about the best possible outcome for each individual who chooses to obtain our services as well as contribute to society at large by establishing the best standards possible in all our endeavors.</p>
                  <p>Our vision is to bring about the best possible outcome for each individual who chooses to obtain our services as well as contribute to society at large by establishing the best standards possible in all our endeavors.</p>

                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-6 col-content'>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
              </div>
            </div>
          </div>
        </div> */}


{/* 
        <div className='contact-section'>
          <div className='container'>
            <div className='about-header mission'>
              <h2>Location Details</h2>
            </div>
            <div className="row contact_details">
              <div className="col-sm-4">
                <h5 className="contact_info_title">Address</h5>
                <ul className='contact_info'>
                  <li>4th Floor ShareMarket Complex,
                    Putalisadak, Kathmandu, Nepal.</li>
                </ul>
              </div>
              <div className="col-sm-4">
                <h5 className="contact_info_title">Hours of Operation</h5>
                <ul className='contact_info'>
                  <li>Sunday to Friday</li>
                  <li>7:00 am - 6:00 pm</li>
                </ul>
              </div>
              <div className="col-sm-4">
                <h5 className="contact_info_title">General Inquiries</h5>
                <ul className='contact_info'>
                  <li><a href="tel:+977014256600">P: +977-01-4256600</a></li>
                  <li><a href="tel:+977-9801030907">P: +977-9801030907</a></li>
                  <li><a href="mailto:info@NAVC.edu.np">E: info@NAVC.edu.np</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}



      </section>



    </>
  )
}
