import React, { useState } from "react";
import parse from "html-react-parser";
import AboutImg from "../../assets/images/about.jpg";
import useAbout from "../../../Pages/Aboutpage/useAbout";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  const { abt, setAbt, counter, setCounter } = useAbout();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <section className="main-section-part" id="about">
        <div className=" container-fluid">
          <div className="row">
            <div className="parallax">
              <div className="overlay"></div>

              <div className="about-section">
                <div className="section-title">
                  <h2 className="text-center">About us</h2>
                  <div className="row text-cen">
                    <div className="col-md-4 col-6">
                      <div className="about-us-para">
                        <div>
                          <span className="about-us-num text-center">
                            {counter.info_value1}
                          </span>
                        </div>
                        <div>
                          <p>{counter.info_title1}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="about-us-para">
                        <div>
                          <span className="about-us-num text-center">
                            {counter.info_value2}
                          </span>
                        </div>
                        <div>
                          <p>{counter.info_title2}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="about-us-para">
                        <div>
                          <span className="about-us-num text-center">
                            {counter.info_value3}
                          </span>
                        </div>
                        <div>
                          <p>{counter.info_title3}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="about_para">
                      <p className="training-paragraph">
                        {parse(abt?.description?.substring(0, 500) || "")}
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={loading}
                      onClick={() => navigate("/aboutpage")}
                      className="about-button"
                    >
                      Learn more
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
