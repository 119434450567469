import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useNews = () => {
    const[news,setNews]=useState([]);
    const[loading, setLoading] = useState(false)
    useEffect(()=>{
        setLoading(true);
        axios({
            url:`${process.env.REACT_APP_API_URL}/news_and_event`,
            method:'GET'
        }).then((res)=>{
            let response = res.data.data.newsAndEvents;
            setNews(response)
            setLoading(false);
        }).catch((err)=>{
            console.log(err,"your error is")
            setLoading(false);
        })
    },[])
  return {
news,
setNews,
loading, setLoading
  }
}

export default useNews