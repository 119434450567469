import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "../assets/images/iconphone.png";
import Image1 from "../assets/images/finallogo.png";
import { FooterAPI } from "../Footer/FooterAPI";
import useNav from "./useNav";

export default function Navbar() {
  const { study, setStudy, service, setService, train, setTrain } = useNav();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    FooterAPI.get()
      .then((res) => {
        console.log(res.data.data.setting);
        let response = res.data.data.setting;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="topbar">
        <div className="container">
          <ul className="tagline social-media">
            <li>
              <Link to="/">
                <div className="logo-nav">
                  <img src={Image1} alt="NAVC" className="" />
                </div>
              </Link>
            </li>
            <li className="topbar-li">
              <a href={data.facebook_link} target="_blank">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li className="topbar-li">
              <a href={data.twitter_link} target="_blank">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li className="topbar-li">
              <a href={data.linkedin_link} target="_blank">
                <i className="fa fa-linkedin" />
              </a>
            </li>
            <li className="topbar-li">
              <a href={data.instagram_link} target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li className="topbar-li">
              <a href={data.youtube_link} target="_blank">
                <i className="fa fa-youtube-play" />
              </a>
            </li>
          </ul>
          <div className="phone">
            <img src={Image} alt="" />
            <a href={data.contact1}>{data.contact1}</a>
          </div>
          {/* end phone */}
        </div>
        {/* end container */}
      </div>
      <div className="container">
        <nav className="navbar navbar-expand-xl navbar-light bg-light">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse menu-main-menu-container"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0 ">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/aboutpage">
                    About Us
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Training
                  </a>
                  <ul
                    className="dropdown-menu dropdown dropdown-content"
                    aria-labelledby="navbarDropdown"
                  >
                    {train?.map((elem) => {
                      return (
                        <>
                          <li className="dropdown-list">
                            <Link
                              className="dropdown-item"
                              to={`/training/${elem.id}`}
                            >
                              {elem.training_title}
                            </Link>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </li>


                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Study Abroad
                  </a>

                  <ul
                    className="dropdown-menu dropdown dropdown-content"
                    aria-labelledby="navbarDropdown"
                  >
                    {study.map((elem) => {
                      return (
                        <>
                          <li className="dropdown-list">
                            <Link
                              className="dropdown-item"
                              to={`/studyabroad/${elem.id}`}
                            >
                              Study in {elem.destination_title}
                            </Link>
                          </li>
                        </>
                      );
                    })}

                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/testimonials">
                    Testimonials
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blogs">
                    Blogs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/news&events">
                    News & Events
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Apply Online
                  </a>
                  <ul
                    className="dropdown-menu dropdown dropdown-content service"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="dropdown-list">
                      <Link className="dropdown-item" to="/apply-online">
                        Schedule & Appointment
                      </Link>
                    </li>
                    {service?.map((item) => {
                      return (
                        <>
                          <li className="dropdown-list">
                            <Link
                              className="dropdown-item"
                              to={`/services/${item.id}`}
                            >
                              {item.service_name}
                            </Link>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
