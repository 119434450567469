import React from 'react'
import Aboutus from '../Components/BodyComponent/Aboutus/Aboutus'
import Mainbanner from '../Components/BodyComponent/mainbanner/Mainbanner'
import News from '../Components/BodyComponent/News/News'

// import Secondbanner from '../Components/BodyComponent/secondbanner/Secondbanner'
import Testimonials from '../Components/BodyComponent/Testimonials/Testimonials'
import Training from '../Components/BodyComponent/training/Training'
import Trainingclass from '../Components/Trainingclass/Trainingclass'

export default function Home() {
  return (
    <>
    <Mainbanner/>
    <Training/>
    <Trainingclass />
    <Aboutus />
    <Testimonials />
    <News />
    </>
  )
}
