import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useTrainingclass = () => {
    const [train, setTrain] = useState([]);
    const [trainDetail, setTrainDetail] = useState(null)
    const[id, setId] = useState(null)
    const [loading, setLoading]=useState(false)
    useEffect(() => {
        setLoading(true);
        axios({
            url: `${process.env.REACT_APP_API_URL}/training/get`,
            method: 'GET'
        }).then((res) => {
            let response = res.data.data.trainings;
            setTrain(response);
            setLoading(false);
        }).catch((err) => {
            console.log(err, "your Error is");
            setLoading(false);
        })
    }, [])

    // Single fetch

    // useEffect(()=>{

    //     if(id){
    //       axios({
    //         url:`${process.env.REACT_APP_API_URL}/training/get/${id}`,
    //         method:'GET'
    //       })
    //       .then((res)=>{
    //         let response = res.data.data.training;
    //         setTrainDetail(response);
    //       })
    //       .catch ((err)=>{
    //         console.log(err,"your Error is");
    //       })
    //     }
       
    //   },[id]);

    return {
        train,
        setTrain,
        trainDetail,
        setId, loading, setLoading
    }
}

export default useTrainingclass