import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import "./Blogpage.css";
import Canada from '../../Components/assets/images/canada.jpg'
import useBlog from './useBlog';
import { useParams } from 'react-router-dom';
import BlogpagedetailsLoader from './Loader/BlogpagedetailsLoader';
const Blogdetailspage = () => {
    
    const{ blog,setBlog,setSlug, slug,blogDetail,loading,setLoading}=useBlog();
    const params = useParams();
    useEffect(()=>{
        if(params.slug){
            setSlug(params.slug)
           
        }

    })
    // console.log("Blog title",blogDetail);
    // console.log("slug is" ,params.slug)
  return (
   <>
    <div className="">
          <div className="section-image card-overlay">
            <img src={Canada} alt="" className="img-fluid" />
          </div>
          <div className="section-study">
            <h1>{blogDetail?.title}</h1>
            {/* <h1>Blog Title</h1> */}
          </div>
        </div>
        <div className="container bg-white blog-banner">
            <div className='can-mar'>
                {/* <h3>How to get good TOEFL score?</h3> */}
                <h3>{blogDetail?.title}</h3>
                {
                    loading?<> <BlogpagedetailsLoader /> </>:<>
                     <p className='card-college-para'>
                    {
                        parse(blogDetail?.description || "")
                    }
                </p>
                    </>
                }
               
                {/* <p className='card-college-para'>Some key guidelines to be taken into consideration:</p> */}
            </div>
            {/* <div className='can-mar'>
                <h3>
                Improve your computer skills
                </h3>
                <p className='card-college-para'>
                Since iBT TOEFL is popular amongst test takers, one should also focus on improving one’s computer skills. If you lack good computer skills, then you may become nervous, careless and slow, which will definitely hamper your overall performance. It is recommended that you be familiar with a computer and the pre-test computer tutorial.
                </p>
            </div> */}
            {/* <div className='can-mar'>
                <h3>
                Master Key Strategies
                </h3>
                <p className='card-college-para'>It is crucial to learn strategies as this will help you to choose and effectively employ the appropriate techniques to accomplish tasks, manage your time, and stay confident. As a consequence, you have a better chance of scoring higher in your TOEFL iBT on the test day.</p>
            </div> */}
            {/* <div className='can-mar'>
                <h3>Make a Study Plan</h3>
                <p className='card-college-para'>How long you study for the TOEFL iBT will depend mainly on your command over English language.  Preferably,   you should give yourself a couple of months to fit in all your study hours without issue. This way you not only have ample time to prepare but also boost up your confidence.</p>
            </div> */}
            {/* <div className='can-mar'>
               <h3>
               Start taking Notes

                   </h3> 
                   <p className='card-college-para'>It is advisable that you take notes while practicing using abbreviation, words and phrases. Developing this skill will not only help you answer the questions but will also be useful at the university level.</p>
            </div> */}
            {/* <div className='can-mar'>
                <h3>
                Manage your Time 
                </h3>
                <p className='card-college-para'>Learn to manage time. You should practice speed-reading techniques like skimming and scanning. This will help you to find the answers quickly and save time. Set yourself certain time to tackle each question and avoid the trap of wasting time with the distracters.</p>
            </div> */}
            {/* <div className='can-mar'>
                <h3>
                Target Your Weaknesses
                </h3><p className='card-college-para'>
                Targeting your weakness is one of the most vital factors that you need to take into consideration, as this can have a very negative effect in your final score. Taking time evaluating various types of questions and trying different strategies is important. For example, if you’re struggling to get a high score in independent writing, you should dedicate more of your study time to improving your writing skills.
                </p>
            </div> */}
            {/* <div className='can-mar'>
                <h3>
                Track Your Progress
                </h3>
                <p className='card-college-para'>
                You should keep track of your progress by taking full-length TOEFL iBT practice tests frequently. The final step is to check your progress so that you can see whether you’re improving.
                </p>
            </div> */}
        </div>
   </>
  )
}

export default Blogdetailspage