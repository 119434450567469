import axios from "axios";

export const AppointmentAPI={
    get:function(data) {
       return axios({
            method:'POST',
            url: `${process.env.REACT_APP_API_URL}/store-online-application`,
            data:data
        })
    }
}
