import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
const Testimonialloader = () => {
  return (
    <>
    <div className="row">
      <div className="col-md-4">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={373} />

          {/* <Skeleton variant="text" width={} /> */}
        </Stack>
      </div>
      <div className="col-md-8">
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"100%"} />
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-md-4">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={373} />

          {/* <Skeleton variant="text" width={} /> */}
        </Stack>
      </div>
      <div className="col-md-8">
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"100%"} />
      </div>
    </div>
    </>
  );
};

export default Testimonialloader;
