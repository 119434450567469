import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'


const useTraining = () => {
  const[loading, setLoading]=useState(false);
  const [abroad, setAbroad] = useState([])
  const [abroadDetail, setAbroadDetail] = useState(null)
  const [id, setId] = useState(null)

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}/studyabroad/get`,
      method: 'GET'
    })
      .then((res) => {
        let response = res.data.data.studyabroads;
        setAbroad(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "your Error is");
        setLoading(false);
      })
  }, []);
  

  // SIngle Fetch
  useEffect(() => {
    setLoading(true)
    if (id) {
      axios({
        url: `${process.env.REACT_APP_API_URL}/studyabroad/get/${id}`,
        method: 'GET'
      })
        .then((res) => {
          let response = res.data.data.studyabroad;
          setAbroadDetail(response);
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, "your Error is");
          setLoading(false)
        })
    }

  }, [id]);

  // url: `https://kabmart.com/api/products/${params.id}`



  return {
    abroad,
    setAbroad,
    abroadDetail,
    setId,
    loading, setLoading
  }
}

export default useTraining