import React from "react";
import { useEffect } from "react";
import parse from "html-react-parser";
import { Link, useParams } from "react-router-dom";
import "./Testpreparation.css";
import Canada from "../../Components/assets/images/canada.jpg";
import image10 from "../../Components/assets/images/ielts.jpg";
import useTraining from "../../Components/BodyComponent/training/useTraining";
import useTrain from "./useTrain";
import TrainingLoader from "../../Components/BodyComponent/training/trainingloader";
import Trainingloader from "./Trainingloader";

const Training = () => {
  const { train, setTrain, id, setId, singleTrain, setSingleTrain ,loading,setLoading} =
    useTrain();
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      setId(params.id);
    }
  }, [params.id]);
  console.log("Params id is ", params.id);
  console.log("setTrain is ", train);
  return (
    <>
      <div className="">
        <div className="section-image card-overlay">
          <img src={Canada} alt="" className="img-fluid" />
        </div>
        <div className="section-study">
          <h1>{singleTrain?.training_title}</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
         {
          loading?<> <Trainingloader /></>:<>
           <div className="col-md-8  ">
            <div className="test-img">
              <img src={singleTrain?.image} alt="ielts-image" />
            </div>
            <h3 className="can-mar">{singleTrain?.training_title}</h3>
            <p className="can-mar card-college-para">
              {parse(singleTrain?.training_description || "")}
            </p>
          </div>

          <div className="col-md-4 animate__animated animate__fadeInUp">
            <div className="test-prep">
              <h3>TEST PREPARATION</h3>
              <ul>
                {train?.map((item) => {
                  return (
                    <>
                      <li className="dropdown-list">
                        <Link to={`/training/${item.id}`}>
                          {item.training_title}
                        </Link>
                      </li>
                    </>
                  );
                })}

                {/* <li><Link to={'/Ielts'}>TOFEL</Link></li>
                        <hr />
                        <li><Link to={'/Ielts'}>SAT</Link></li>
                        <hr />
                        <li><Link to={'/'}>GRE</Link></li> */}
              </ul>
            </div>
          </div>
          </>
         }
        </div>
      </div>
    </>
  );
};

export default Training;
