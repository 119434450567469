import React, { useEffect } from "react";
import parse from "html-react-parser";
import "./StudyAbroaddetails.css";
import { Link } from "react-router-dom";
import { useState } from "react";

import useTraining from "../../Components/BodyComponent/training/useTraining";
import { useParams } from "react-router-dom";
import StudyAbroadLoader from "./Loader/StudyAbroadLoader";

const StudyAbroaddetails = () => {
  const [country, setCountry] = useState([
   
  ]);
  const { abroad, setAbroad, setId, abroadDetail,loading,setLoading } = useTraining();
  const params = useParams();

  const [activeTab, setActiveTab] = useState(0);
 

  const handleActiveTab = (id) => {
    setActiveTab(id);
  };

  useEffect(() => {
    if (params.id) {
      setId(params.id);
    }
  }, [params]);

  return (
    <>
      <div className="container can-mar">
    
        {
          loading?<> <StudyAbroadLoader /></>:<>
              <h3 className='intro'>Study in {abroadDetail?.destination_title}</h3>
           <div className='image-sections '>
          <img src={abroadDetail?.destination_image} alt="Study in Canada" className='img-fluid' />
        </div>
        <p className="mt-3">{parse(abroadDetail?.description || "")}</p>
        <div className="can-mar">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              {abroadDetail?.subtitles?.map((item, id) => (
                <button
                  class="nav-link "
                  id={`description-${id}`}
                  data-bs-toggle="tab"
                  data-bs-target={`description-${id}`}
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  onClick={(e) => handleActiveTab(id)}
                >
                  {item.subtitle_name}
                </button>
              ))}
          
            </div>
          </nav>
          <div className="tab-content abroaddetails-para " id="nav-tabContent">
       
            {abroadDetail?.subtitles?.map((item, id) => (
              <div
                class={
                  activeTab == id
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id={`description-${id}`}
                role="tabpanel"
                aria-labelledby={`description-${id}`}
              >
                <div className="can-mar">
                  {" "}
            <p>
            {parse(item.subtitle_description || "")}
            </p>
                 
                </div>
              </div>
            ))}
     
          </div>
        </div>
          </>
        }
       
      
     
      </div>
     
      
    </>
  );
};

export default StudyAbroaddetails;
