import React, { useEffect, useState } from 'react'
import axios from 'axios'

const useTestimonial = () => {
    const [monials,setMonials]=useState([]);
    const [loading,setLoading]=useState(false);
   useEffect(()=>{
    setLoading(true);
    axios({
        url:`${process.env.REACT_APP_API_URL}/get-testimonials`,
        method:"GET"
    }).then((res)=>{
        console.log(res.data.data);
        let response=res.data.data.testimonials;
        setMonials(response);
        setLoading(false);
    }).catch((err)=>{
        console.log(err,"Your error is")
        setLoading(false);
    })
   },[])

  return {
monials,
setMonials,
loading,
setLoading

  }
  
  
}

export default useTestimonial