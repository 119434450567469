import React from "react";
import { Link } from "react-router-dom";
import Image1 from "../assets/images/icon1.png";
import Image2 from "../assets/images/icon2.png";
import Image3 from "../assets/images/icon3.png";
import Image4 from "../assets/images/icon4.png";
import useTrainingclass from "./useTrainingclass";
import useNav from "../Navbar/useNav";
import Trainingclassloader from "./Trainingclassloader";

const Trainingclass = () => {

  // const { train, setTrain, setId } = useTrainingclass();
  const { study, setStudy, train, setTrain, } = useNav();
  const { loading, setLoading } = useTrainingclass()

  return (
    <>
      <section id="training">
        <div className="main-section-part">
          <div className="container ">
            <div className="section-title">
              <h2>Training Class</h2>
            </div>

            <div className="row">
              {loading ? <> <Trainingclassloader/></> : <>
                {
                  train.map((elem) => {
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-3">
                        <div className="training-name">
                          {/* <span>01</span> */}
                          <Link to={`/training/${elem.id}`}>
                         <div className="training-heading">
                         <h4>{elem?.training_title}</h4>
                         </div>
                        
                          <div>
                            <img src={elem.image} alt="image" className="" />
                          </div>
                          </Link>
                        </div>
                      </div>
                    )

                  }
                  )
                }
              </>
              }


              {/* <div className="col-lg-3 col-md-3 col-sm-6 mb-3">
                <div className="training-name">
                  <span>02</span>
                  <h4>Analysis</h4>
                  <div>
                    <img src={Image2} alt="image" className="" />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 mb-3">
                <div className="training-name">
                  <span>03</span>
                  <h4>Marketing</h4>
                  <div>
                    <img src={Image3} alt="image" className="" />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 mb-3">
                <div className="training-name">
                  <span>04</span>
                  <h4>Research</h4>
                  <div>
                    <img src={Image4} alt="image" className="" />
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trainingclass;
